$shimmer-bg: #FAFAFA;
$shimmer-color: #F2F2F2;

.shimmer {
	background: #FAFAFA;
	background-image: linear-gradient(to right, $shimmer-bg 0%, $shimmer-color 30%, $shimmer-bg 50%, $shimmer-bg 100%);
	background-repeat: no-repeat;
	background-size: 800px 400px;
	display: inline-block;
	position: relative;
	
	animation-duration: 1s;
	-webkit-animation-duration: 1s;

	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;

	animation-name: placeholderShimmer;
	-webkit-animation-name: placeholderShimmer;

	animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}
	
	100% {
		background-position: 468px 0;
	}
}

@-webkit-keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}
	
	100% {
		background-position: 468px 0;
	}
}