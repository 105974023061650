.react-datepicker {
	font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	border: none;
	border-radius: $border-radius;
	background-color: transparent;

	.react-datepicker__header {
		background-color: #218FFE;
		border: none;
		border-radius: $border-radius $border-radius 0px 0px;
	}

	
	.react-datepicker__current-month {
		color: white;
	}
	
	/* TIME */
	.react-datepicker-time__header {
		color: white;
	}

	.react-datepicker__time-container {
		margin-left: 12px;
		border: none;
		border-radius: 0px 0px $border-radius $border-radius;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
		width: 100px;
	}
	
	.react-datepicker__time {
		border-radius: $border-radius;
		width: 100%;
	}
	.react-datepicker__time-box {
		width: 100% !important;
		margin: 0px !important;
	}

	.react-datepicker__time-list-item {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		&--selected {
			background-color: #FA9C1E !important;
		}
	}

	.react-datepicker__month-container {
		background-color: white;
		margin-left: 6px;
		border-radius: $border-radius;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	}

	.react-datepicker__day-name {
		color: white;
	}

	.react-datepicker__day {
		&--selected {
			background-color: #FA9C1E;
		}
		&:focus {
			outline: none;
		}
	}

	.react-datepicker__navigation {
		&:focus {
			outline: none;
		}
		&--previous {
			border-right-color: white;
		}
		&--next {
			border-left-color: white;
			right: 115px;
		}
	}
}