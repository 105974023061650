@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800&display=swap');

body {
	margin: 0;
	font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'Koverwatch';
	src: url('./fonts/koverwatch.ttf') format('truetype');
}

@font-face {
	font-family: 'Koverwatch';
	src: url('./fonts/bignoodletoo.ttf') format('truetype');
	font-style: italic;
}

$border-radius: 8px;

.app {
	height: 100vh;
	max-width: 100%;
	width: 100vw;
	.page {
		position: relative;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		color: #212121;
		background-color: #F2F2F2;
	}

	@import './DateTimeSelector.scss';
	@import './Shimmer.scss';
}

@import './Tippy.scss';

h1, h2, h3, h4 {
	font-family: 'Koverwatch';
	font-weight: normal;
	font-style: italic;
	letter-spacing: 2px;
	margin: 0px;
}
